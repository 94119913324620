import { FunctionComponent } from "react";
import styles from "./ZoobalinkPCTop.module.css";
const ZoobalinkPCTop: FunctionComponent = () => {
  return (
    <div className={styles.zoobalinkPcTop}>
      <img className={styles.maskGroupIcon} alt="" src="/mask-group@2x.png" />
      <b className={styles.title}>社内ITからビジネスを変える。</b>
      <div className={styles.title1}>
        <p className={styles.p}>
          情報システム部門のパートナーとして、あなたと伴走し
        </p>
        <p className={styles.p}>
          企業規模や業態に合わせて、最新技術と知識でサポートします。
        </p>
      </div>
      <div className={styles.zoobalinkPcTopChild} />
      <img className={styles.zoobalinkPcTopItem} alt="" src="/group-13.svg" />
      <div className={styles.frameParent}>
        <div className={styles.titleParent}>
          <a href="#service">
            <div className={styles.title2}>サービス内容</div>
          </a>
          <a href="#consultants">
            <div className={styles.title2}>コンサルティング人材紹介</div>
          </a>
          <a href="#zoobaLinkService">
            <div className={styles.title2}>zooba linkができること</div>
          </a>
          <a href="#price">
            <div className={styles.title2}>料金例</div>
          </a>
        </div>
        <a href="https://www.zooba.ai/contact" style={{ textDecoration: 'none' }}>
        <div className={styles.frameWrapper}>
          <div className={styles.titleGroup}>
            <div className={styles.title2White}>お問い合わせ</div>
            <img
              className={styles.chevronRightIcon}
              alt=""
              src="/chevron-right.svg"
            />
          </div>
        </div>
        </a>
      </div>
      <img
        className={styles.zoobalinkPcTopInner}
        alt=""
        src="/group-3740.svg"
      />
      <div className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        <div className={styles.frameItem} />
        <b className={styles.title7}>zooba linkができること</b>
        <div className={styles.frameGroup}>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxChild}
                alt=""
                src="/group-3748.svg"
              />
            </div>
            <div className={styles.title8}>アカウント棚卸し</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxItem}
                alt=""
                src="/group-3746.svg"
              />
            </div>
            <div className={styles.title8}>デバイス棚卸し</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxInner}
                alt=""
                src="/group-3749.svg"
              />
            </div>
            <div className={styles.title8}>入退職プロセス整理</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img className={styles.groupIcon} alt="" src="/group-3747.svg" />
            </div>
            <div className={styles.title8}>ワークフロー開発</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxChild1}
                alt=""
                src="/group-3745.svg"
              />
            </div>
            <div className={styles.title8}>セキュリティーポリシー作成</div>
          </div>
        </div>
        <img className={styles.vectorIcon} alt="" src="/vector.svg" />
        <div className={styles.title13}>
          <p className={styles.p}>
            <span className={styles.span}>私たちは中小企業の【</span>
            <b className={styles.span}>
              ITツール導入を支援するコンサルティングサービス
            </b>
            <span>】を提供しています。</span>
          </p>
          <p className={styles.p}>
            ITのコンサルタントやスペシャリストが、システムの導入に関するご支援を致します。
          </p>
        </div>
        <div className={styles.titleWrapper}>
          <b className={styles.title14}>
            スペシャリストがITツール導入を支援します
          </b>
        </div>
      </div>
      <div className={styles.rectangleDiv} />
      <div className={styles.rectangleGroup}>
        <div className={styles.frameInner} />
        <div className={styles.titleContainer}>
          <b className={styles.title15}>zooba linkができること</b>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxChild}
                alt=""
                src="/group-3748.svg"
              />
            </div>
            <div className={styles.title8}>アカウント棚卸し</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxItem}
                alt=""
                src="/group-3746.svg"
              />
            </div>
            <div className={styles.title8}>デバイス棚卸し</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxInner}
                alt=""
                src="/group-3749.svg"
              />
            </div>
            <div className={styles.title8}>入退職プロセス整理</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img className={styles.groupIcon} alt="" src="/group-3747.svg" />
            </div>
            <div className={styles.title8}>ワークフロー開発</div>
          </div>
          <div className={styles.settingsAccountBoxParent}>
            <div className={styles.settingsAccountBox}>
              <img
                className={styles.settingsAccountBoxChild1}
                alt=""
                src="/group-3745.svg"
              />
            </div>
            <div className={styles.title8}>セキュリティーポリシー作成</div>
          </div>
        </div>
      </div>
      <div className={styles.zoobalinkPcTopChild1} />
      <img className={styles.maskGroupIcon1} alt="" src="/mask-group1@2x.png" />
      <b className={styles.it3}>
        <p className={styles.p}>私たち情報システムスペシャリストが</p>
        <p className={styles.p}>ITツール導入を支援します</p>
      </b>
      <div className={styles.ourSpecialistsSupportContainer}>
        <p className={styles.p}>Our specialists</p>
        <p className={styles.p}>{`Support for `}</p>
        <p className={styles.p}>IT tool implementation.</p>
      </div>
      <i className={styles.ourSpecialistsSupportContainer1}>
        <p className={styles.p}>{`Our specialists Support `}</p>
        <p className={styles.p}>{`for IT tool `}</p>
        <p className={styles.p}>implementation.</p>
      </i>
      <div className={styles.ourSpecialistsSupport1}>
        Our specialists Support for IT tool implementation.
      </div>
      <div className={styles.title21}>
        私達はITツール導入を支援するコンサルティングサービスを提供しています。お客様のニーズに合わせて、設計構築、トラブルシューティング、パフォーマンスの最適化、セキュリティの強化など、包括的なコンサルティングサービスを提供いたします。情報システム部門のパートナーとして伴走し、最適な解決策をご提案いたします。
      </div>
      <div className={styles.zoobalinkPcTopChild2} />
      <div className={styles.zoobalinkPcTopChild3} />
      <div className={styles.zoobalinkPcTopChild4} />
      <div className={styles.title22}>
        IT業務コンサルティングのパートナーとして、お客様のビジネスを効果的にサポートいたします
      </div>
      <b className={styles.title23} id="consultants">コンサルティング人材紹介</b>
      <b className={styles.title24} id="price">サービス料金</b>
      <div className={styles.title25}>紹介までのフロー</div>
      <img className={styles.maskGroupIcon2} alt="" src="/mask-group2@2x.png" />
      <img className={styles.maskGroupIcon3} alt="" src="/mask-group3@2x.png" />
      <div className={styles.title26}>
        <p className={styles.p5}>
          <span className={styles.microsoftEntepriseSupportName}>
            <span>名和 彩音</span>
          </span>
        </p>
        <p className={styles.tetsuyaKiyokawa}>
          <span>
            <span className={styles.microsoftEntepriseSupportEng}>
              <span>Ayane Nawa</span>
            </span>
          </span>
        </p>
        <p className={styles.p}>
          <span>
            <span className={styles.microsoftEntepriseSupport}>
              <span>&nbsp;</span>
            </span>
          </span>
        </p>
        <p className={styles.p}>
        <p className={styles.p5}>
          <span>
            <b>経歴</b>
          </span>
        </p>
          <span>
            <span className={styles.microsoftEntepriseSupport}>
              Microsoft Enteprise Support、DeNA インフラエンジニア & IT戦略ITマネージャー
            </span>
          </span>
        </p>
        <p className={styles.p}>
          <span>
            <span className={styles.microsoftEntepriseSupport}>&nbsp;</span>
          </span>
        </p>
        <p className={styles.p5}>
          <span>
            <b>得意分野</b>
          </span>
        </p>
        <p className={styles.p}>
          <span>
            <span className={styles.microsoftEntepriseSupport}>
              <div>Windows 全般 、サーバー管理(Windows、Linux)、資産管理(Mac、Windows、Android、iOS)</div>
              <div>ID管理、Idp設計、802.1x、SaaS運用、CSIRTセキュリティ施策、NW、IT採用、コストコントロール</div>
            </span>
          </span>
        </p>
      </div>
      <div className={styles.title27}>
        <p className={styles.p5}>
          <span className={styles.microsoftEntepriseSupportName}>
            <span>清川 哲也</span>
          </span>
        </p>
        <p className={styles.tetsuyaKiyokawa}>
          <span>
            <span className={styles.microsoftEntepriseSupportEng}>
              <span>Tetsuya Kiyokawa</span>
            </span>
          </span>
        </p>
        <p className={styles.p}>
          <span>
            <span className={styles.microsoftEntepriseSupport}>
              <span>&nbsp;</span>
            </span>
          </span>
        </p>
        <p className={styles.p}>
        <p className={styles.p5}>
          <span>
            <b>経歴</b>
          </span>
        </p>
          <span>
            <span className={styles.microsoftEntepriseSupport}>
              DeNA IT戦略 グローバルオペレーションマネージャー、メルカリ
              コーポレートエンジニアリングマネージャー、DX担当
            </span>
          </span>
        </p>
        <p className={styles.p}>
          <span>
            <span className={styles.microsoftEntepriseSupport}>&nbsp;</span>
          </span>
        </p>
        <p className={styles.p5}>
          <span>
            <b>得意分野</b>
          </span>
        </p>
        <p className={styles.p}>
          <span>
            <span className={styles.microsoftEntepriseSupport}>
              <div>IT戦略、PMI、部門組成、マネジメント、PM、PdM、サーバー管理（Windows、Linux）</div>
              <div>認証認可、CSIRTセキュリティ施策、インフラ、NW</div>
            </span>
          </span>
        </p>
      </div>
      <img className={styles.rectangleIcon} alt="" src="/rectangle-1924.svg" />
      <img
        className={styles.zoobalinkPcTopChild5}
        alt=""
        src="/rectangle-1925.svg"
      />
      <img
        className={styles.zoobalinkPcTopChild6}
        alt=""
        src="/rectangle-1925.svg"
      />
      <img
        className={styles.zoobalinkPcTopChild7}
        alt=""
        src="/rectangle-1925.svg"
      />
      <img
        className={styles.zoobalinkPcTopChild8}
        alt=""
        src="/rectangle-1925.svg"
      />
      <div className={styles.title28}>ヒアリング</div>
      <div className={styles.title29}>お見積もり・NDA締結</div>
      <div className={styles.title30}>エンジニア紹介・面談</div>
      <div className={styles.title31}>納品</div>
      <div className={styles.title32}>継続判断契約</div>
      <b className={styles.title33} id="zoobaLinkService">その他zooba linkができること</b>
      <div className={styles.zoobalinkPcTopInner1}>
        <div className={styles.groupParent}>
          <img className={styles.frameChild1} alt="" src="/group-37481.svg" />
          <div className={styles.frameParent1}>
            <div className={styles.titleFrame}>
              <div className={styles.title8}>アカウント棚卸し支援</div>
            </div>
            <div className={styles.title35}>
              アセスメントにて全アカウントを把握し、アカウント棚卸しを通してセキュリティと業務効率を向上します。
            </div>
          </div>
        </div>
      </div>
      <div className={styles.zoobalinkPcTopInner2}>
        <div className={styles.groupContainer}>
          <img className={styles.frameChild2} alt="" src="/group-37461.svg" />
          <div className={styles.frameParent1}>
            <div className={styles.titleFrame}>
              <div className={styles.title8}>デバイス棚卸し支援</div>
            </div>
            <div className={styles.title35}>
              全てのデバイスを追跡し、管理の容易さとセキュリティを確保するためのデバイス棚卸しを支援します。
            </div>
          </div>
        </div>
      </div>
      <div className={styles.zoobalinkPcTopInner3}>
        <div className={styles.groupParent}>
          <img
            className={styles.settingsAccountBoxInner}
            alt=""
            src="/group-37491.svg"
          />
          <div className={styles.frameParent1}>
            <div className={styles.titleFrame}>
              <div className={styles.title8}>入退職プロセス整理</div>
            </div>
            <div className={styles.title35}>
              スムーズな従業員の入退職プロセスを確立し、アカウントの追加と削除を効率化します。
            </div>
          </div>
        </div>
      </div>
      <div className={styles.zoobalinkPcTopInner4}>
        <div className={styles.groupParent2}>
          <img className={styles.groupIcon} alt="" src="/group-37471.svg" />
          <img className={styles.frameChild5} alt="" src="/group-37492.svg" />
          <div className={styles.frameParent1}>
            <div className={styles.titleFrame}>
              <div className={styles.title8}>ワークフロー開発</div>
            </div>
            <div className={styles.title41}>
              業務プロセスの整理と最適化により、生産性を向上させるワークフローの開発を行います。
            </div>
          </div>
        </div>
      </div>
      <div className={styles.zoobalinkPcTopInner5}>
        <div className={styles.groupParent3}>
          <img
            className={styles.settingsAccountBoxChild1}
            alt=""
            src="/group-37451.svg"
          />
          <div className={styles.frameParent1}>
            <div className={styles.titleFrame}>
              <div className={styles.title8}>セキュリティーポリシー作成</div>
            </div>
            <div className={styles.title41}>
              お客様のニーズに合わせたセキュリティポリシーを策定し、重要なデータとリソースを保護します。
            </div>
          </div>
        </div>
      </div>
      <a href="https://www.zooba.ai/contact">
        <div className={styles.titleParent1}>
          <div className={styles.title2White}>お問い合わせはこちら</div>
          <img
            className={styles.chevronRightIcon}
            alt=""
            src="/chevron-right.svg"
          />
        </div>
      </a>
      <div className={styles.vectorParent}>
        <img className={styles.frameChild7} alt="" src="/vector-10.svg" />
        <img className={styles.frameChild8} alt="" src="/vector-11.svg" />
        <div className={styles.title45}>
          お客様のご要望や業務内容をお伺いします。現状の業務フローや課題点を詳しくお聞きし、最適なITツールをご提案します。
        </div>
        <div className={styles.title46}>
          お客様に合ったITツールをご提案し、導入プランを作成します。導入にあたってのスケジュールやコスト、導入後のアフターフォローなども含めて、プランを策定します。
        </div>
        <div className={styles.title47}>
          ITツールの導入に向けて、担当者様と協力しながら、必要な交渉や調整を行います。導入計画の遅延やストレスを軽減します。
        </div>
        <div className={styles.titleWrapper5}>
          <div className={styles.title15}>
            全行程を約1ヶ月~3ヶ月程でコンサルティングを行います
          </div>
        </div>
        <b className={styles.title49} id="service">サービス内容</b>
        <div className={styles.title50}>ヒアリング</div>
        <div className={styles.titleWrapper6}>
          <div className={styles.title15}>01</div>
        </div>
        <div className={styles.titleWrapper7}>
          <div className={styles.title15}>02</div>
        </div>
        <div className={styles.titleWrapper8}>
          <div className={styles.title15}>03</div>
        </div>
        <div className={styles.title54}>プランニング・設計</div>
        <div className={styles.title55}>
          ベンダー調整、交渉、スケジュール調整
        </div>
        <div className={styles.frameChild9} />
        <div className={styles.frameChild10} />
        <div className={styles.frameChild11} />
        <img className={styles.frameIcon} alt="" src="/frame-242.svg" />
        <img className={styles.frameIcon1} alt="" src="/frame.svg" />
        <img className={styles.frameChild12} alt="" src="/frame-245.svg" />
      </div>
      <div className={styles.titleWrapper9}>
        <div className={styles.title8}>Step 1</div>
      </div>
      <div className={styles.titleWrapper10}>
        <div className={styles.title8}>Step 2</div>
      </div>
      <div className={styles.titleWrapper11}>
        <div className={styles.title8}>Step 3</div>
      </div>
      <div className={styles.titleWrapper12}>
        <div className={styles.title8}>Step 4</div>
      </div>
      <div className={styles.titleWrapper13}>
        <div className={styles.title8}>Step 5</div>
      </div>
      <div className={styles.rectangleContainer}>
        <div className={styles.groupChild} />
        <img className={styles.groupItem} alt="" src="/rectangle-2339.svg" />
        <div className={styles.groupInner} />
        <img className={styles.groupChild1} alt="" src="/group-3771.svg" />
        <b className={styles.title61}>Meeting Plan</b>
        <div className={styles.groupDiv}>
          <b className={styles.title62}>¥200,000~</b>
          <div className={styles.title63}>/ 月</div>
        </div>
        <div className={styles.frameParent6}>
          <div className={styles.checkCircleParent}>
            <img
              className={styles.chevronRightIcon}
              alt=""
              src="/check-circle.svg"
            />
            <div className={styles.title8}>いつでもチャットで聞ける</div>
          </div>
          <div className={styles.checkCircleParent}>
            <img
              className={styles.chevronRightIcon}
              alt=""
              src="/check-circle.svg"
            />
            <div className={styles.title8}>資料・作業での納品</div>
          </div>
          <div className={styles.checkCircleParent}>
            <img
              className={styles.chevronRightIcon}
              alt=""
              src="/check-circle.svg"
            />
            <div className={styles.title8}>
              専門のエンジニアが一緒にサポート
            </div>
          </div>
        </div>
        <div className={styles.titleWrapper14}>
          <div className={styles.title2White}>ミーティング2回、20時間程度</div>
        </div>
      </div>
      <div className={styles.rectangleParent1}>
        <div className={styles.groupChild} />
        <img className={styles.groupItem} alt="" src="/rectangle-2339.svg" />
        <div className={styles.groupInner} />
        <img className={styles.chatPlanIcon} alt="" src="/chat-plan.svg" />
        <b className={styles.title68}>Chat Plan</b>
        <div className={styles.titleParent2}>
          <b className={styles.title62}>¥50,000~</b>
          <div className={styles.title70}>/ 月</div>
        </div>
        <div className={styles.frameWrapper1}>
          <div className={styles.checkCircleParent}>
            <img
              className={styles.chevronRightIcon}
              alt=""
              src="/check-circle1.svg"
            />
            <div className={styles.title8}>いつでもチャットで聞ける</div>
          </div>
        </div>
        <div className={styles.titleWrapper15}>
          <div className={styles.title2White}>チャットサポートのみのプラン</div>
        </div>
      </div>
      <div className={styles.rectangleParent2}>
        <div className={styles.groupChild4} />
        <div className={styles.title73}>©︎2021 zooba inc.</div>
        <div className={styles.titleParent3}>
          <a href="https://www.zooba.ai/company">
            <div className={styles.title74}>会社概要</div>
          </a>
          <a href="https://www.zooba.ai/legal/terms">
            <div className={styles.title74}>利用規約</div>
          </a>
            <a href="https://www.zooba.ai/legal/privacy">
          <div className={styles.title74}>プライバシーポリシー</div>
          </a>
          <a href="https://www.zooba.ai/legal/security">
            <div className={styles.title77}>情報セキュリティ基本方針</div>
          </a>
        </div>
        <img className={styles.groupChild5} alt="" src="/group-3772.svg" />
      </div>
      <div className={styles.zoobalinkPcTopChild9} />
      <div className={styles.groupParent4}>
        <div className={styles.ellipseParent}>
          <div className={styles.groupChild6} />
          <img className={styles.groupChild7} alt="" src="/group-3776.svg" />
          <div className={styles.title78}>
            セキュリティを強化し、ユーザーエクスペリエンスを向上します。設計から展開まで包括的なサービスを提供します。
          </div>
          <div className={styles.title79}>SSO導入支援</div>
          <img className={styles.icon} alt="" src="/--1.svg" />
        </div>
        <div className={styles.ellipseGroup}>
          <div className={styles.groupChild6} />
          <img className={styles.groupChild9} alt="" src="/group-3777.svg" />
          <div className={styles.title78}>
            デバイスの簡素化されたセットアップと設定を実現します。自動化されたプロビジョニングやカスタマイズを提供します。
          </div>
          <div className={styles.title81}>ゼロタッチキッティング</div>
        </div>
        <div className={styles.ellipseContainer}>
          <div className={styles.groupChild6} />
          <img className={styles.icon1} alt="" src="/--11.svg" />
          <div className={styles.title78}>
            ゼロトラスト原則に基づいたネットワークとデータセキュリティを確立します。アクセス制御や脅威対策を統合します。
          </div>
          <div className={styles.title83}>ゼロトラスト運用設計</div>
        </div>
        <div className={styles.ellipseParent1}>
          <div className={styles.groupChild6} />
          <img className={styles.icon2} alt="" src="/--12.svg" />
          <div className={styles.title78}>
            Google
            Workspacesなどのセットアップから運用管理まで包括的なサポートを提供します。効果的なコラボレーション環境を実現し、生産性と情報共有を向上させます。
          </div>
          <div className={styles.title85}>コラボレーションツール運用</div>
        </div>
        <div className={styles.ellipseParent2}>
          <div className={styles.groupChild6} />
          <img className={styles.icon3} alt="" src="/--13.svg" />
          <div className={styles.title78}>
            モバイルデバイスとエンドポイントの管理を効率化します。セキュアなデバイス管理やアプリケーションデプロイをサポートします。
          </div>
          <div className={styles.title87}>Azure Intune / Jamf Pro 運用</div>
        </div>
        <div className={styles.ellipseParent3}>
          <div className={styles.groupChild6} />
          <img className={styles.chatIcon} alt="" src="/chat.svg" />
          <div className={styles.title78}>
            困った時にチャットツールですぐに質問ができ、環境に合わせたベストプラクティスを提案します。
          </div>
          <div className={styles.title83}>チャットツールで相談</div>
        </div>
      </div>
      <img className={styles.maskGroupIcon4} alt="" src="/mask-group4@2x.png" />
      <div className={styles.titleParent4}>
        <div className={styles.title90}>ITコンサルティングサービス</div>
        <img className={styles.groupChild14} alt="" src="/group-3743.svg" />
        <a href="https://www.zooba.ai/contact">
          <div className={styles.titleParent5}>
            <div className={styles.title2White}>お問い合わせはこちら</div>
            <img
              className={styles.chevronRightIcon}
              alt=""
              src="/chevron-right1.svg"
            />
          </div>
        </a>
        <b className={styles.title92}>
          情報システム部門のスペシャリストが、あなたと共に。
        </b>
      </div>
      <img
        className={styles.zoobalinkPcTopChild10}
        alt=""
        src="/group-3741.svg"
      />
    </div>
  );
};

export default ZoobalinkPCTop;
